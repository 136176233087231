import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createHead } from '@vueuse/head'
import Bootstrap3 from 'bootstrap-vue-3'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons';
import store from './store'
import router from './router'
import App from './App.vue'
import FactoryRepository from '@/api/FactoryRepository'
import '@/libs/toastification'
import JWT from '@/auth/JWT'
import helperFunctions from '@/libs/helper'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const app = createApp(App)
app.use(Bootstrap3)
app.component('BootstrapIcon', BootstrapIcon);
app.use(router)
app.use(store)
const head = createHead()
app.use(head)

/* language files */
import textEn from '@/locales/text-en.json'

const messages = {
  en: textEn
}
const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
})
app.use(i18n)

const helper = {
  install() {
    app.helper = helperFunctions
    app.config.globalProperties.$helper = helperFunctions
  },
}
app.use(helper)

// JWT
const Jwt = {
  install() {
    app.JWT = JWT
    app.config.globalProperties.$JWT = JWT
  },
}
app.use(Jwt)

// API Factory
FactoryRepository.init(JWT)
const api = {
  install() {
    app.Enums = FactoryRepository.get('Enums')
    app.config.globalProperties.$Enums = FactoryRepository.get('Enums')

    app.Users = FactoryRepository.get('Users')
    app.config.globalProperties.$Users = FactoryRepository.get('Users')

    app.Files = FactoryRepository.get('Files')
    app.config.globalProperties.$Files = FactoryRepository.get('Files')

    app.Skills = FactoryRepository.get('Skills')
    app.config.globalProperties.$Skills = FactoryRepository.get('Skills')

    app.SkillMatrices = FactoryRepository.get('SkillMatrices')
    app.config.globalProperties.$SkillMatrices = FactoryRepository.get('SkillMatrices')

    app.EntityOptions = FactoryRepository.get('EntityOptions')
    app.config.globalProperties.$EntityOptions = FactoryRepository.get('EntityOptions')

    app.Items = FactoryRepository.get('Items')
    app.config.globalProperties.$Items = FactoryRepository.get('Items')

    app.ItemHistories = FactoryRepository.get('ItemHistories')
    app.config.globalProperties.$ItemHistories = FactoryRepository.get('ItemHistories')

    app.Vendors = FactoryRepository.get('Vendors')
    app.config.globalProperties.$Vendors = FactoryRepository.get('Vendors')

    app.Orders = FactoryRepository.get('Orders')
    app.config.globalProperties.$Orders = FactoryRepository.get('Orders')

    app.Pdfs = FactoryRepository.get('Pdfs')
    app.config.globalProperties.$Pdfs = FactoryRepository.get('Pdfs')

    app.PrintHistories = FactoryRepository.get('PrintHistories')
    app.config.globalProperties.$PrintHistories = FactoryRepository.get('PrintHistories')

    app.Emails = FactoryRepository.get('Emails')
    app.config.globalProperties.$Emails = FactoryRepository.get('Emails')

    app.EmailTemplates = FactoryRepository.get('EmailTemplates')
    app.config.globalProperties.$EmailTemplates = FactoryRepository.get('EmailTemplates')

    app.Exports = FactoryRepository.get('Exports')
    app.config.globalProperties.$Exports = FactoryRepository.get('Exports')

    app.Roles = FactoryRepository.get('Roles')
    app.config.globalProperties.$Roles = FactoryRepository.get('Roles')
  },
}
app.config.globalProperties.$filters = {
  price(num) {
    if (num) {
      return parseFloat(num).toFixed(0).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
    return num
  }
}
app.use(api)
app.mount('#app')
