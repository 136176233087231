<template>
  <div class="navbar-top">
    <a href="javascript:void(0)" class="navbar-toggler" @click="showNav">
      <BootstrapIcon
          icon="list"
          size="1x"
        />
    </a>
    <!-- User circle info -->
    <router-link v-if="user" to="/profile" class="user">
      <b-list-group>
        <b-list-group-item class="user-circle">

          <div class="user-info">
            {{ user.givenName }} {{ user.familyName }}
            <span>{{ $helper.getEnumTranslation("roles", user.role, $i18n.locale) }}</span>
          </div>
          <span class="b-avatar bg-primary rounded-circle user-photo">
            <BootstrapIcon v-if="!user.fileUrl"  icon="person-fill" size="1x" />
            <img v-if="user.fileUrl" :src="user.fileUrl" alt="">
          </span>
         
        </b-list-group-item>
      </b-list-group>
    </router-link>
  </div>
</template>

<script>
import { getUserData } from "@/auth/utils";

export default {
  data() {
    return {
      user: getUserData(),
    }
  },
  methods: {
    showNav() {
      this.$emit('navToggle', true)
    }
  }
};
</script>

<style>
</style>
