import moment from 'moment'
import enumsEn from '@/locales/enum-en.json'
import {getUserData} from '@/auth/utils'

const slugify = txt => {
  let str = txt
  str = str.replace(/^\s+|\s+$/g, '')

  // Make the string lowercase
  str = str.toLowerCase()

  // Remove accents, swap ñ for n, etc
  const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;'
  const to = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa______'
  for (let i = 0, l = from.length; i < l; i += 1) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  // Remove invalid chars
  str = str.replace(/[^a-z0-9 -]/g, '')
    // Collapse whitespace and replace by _
    .replace(/\s+/g, '_')
    // Collapse dashes
    .replace(/-+/g, '_')

  return str
}

const slugifyURL = txt => {
  let str = txt
  str = str.replace(/^\s+|\s+$/g, '')

  // Make the string lowercase
  str = str.toLowerCase()

  // Remove accents, swap ñ for n, etc
  const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽĹĽľĺáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/-,:;'
  const to = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZLLllaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------'
  for (let i = 0, l = from.length; i < l; i += 1) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  // Remove invalid chars
  str = str.replace(/[^a-z0-9 -]/g, '')
    // Collapse whitespace and replace by _
    .replace(/\s+/g, '-')
    // Collapse dashes
    .replace(/-+/g, '-')

  return str
}

const generateRandomString = length => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const showToast = (toast, toastComponent, title, text, variant) => {
  toast({
    component: toastComponent,
    position: 'top-right',
    props: {
      title,
      text,
      variant,
    },
  })
}

const getEnumTranslation = (name, key) => {
  let enums = enumsEn
  if (enums[name]) {
    if (enums[name][key]) {
      return enums[name][key]
    }
  } else if (enums[key]) {
    return enums[key]
  }
  return null
}

const getAddUrl = (root) => {
  return `/${root}/add`;
}

const getDetailUrl = (root, id) => {
  return `/${root}/${id}`;
}

const getEditUrl = (root, id) => {
  return `/${root}/${id}/edit`;
}

const getViewUrl = (root, id) => {
  return `/${root}/${id}/view`;
}

const getCountriesList = () => {
  return ["Afghan",
    "Albanian",
    "Algerian",
    "Argentinian",
    "Australian",
    "Austrian",
    "Bangladeshi",
    "Belgian",
    "Bolivian",
    "Batswana",
    "Brazilian",
    "Bulgarian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Czech",
    "Danish",
    "Dominican",
    "Ecuadorian",
    "Egyptian",
    "Salvadorian",
    "English",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Finnish",
    "French",
    "German",
    "Ghanaian",
    "Greek",
    "Guatemalan",
    "Haitian",
    "Honduran",
    "Hungarian",
    "Icelandic",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kenyan",
    "Kuwaiti",
    "Lao",
    "Latvian",
    "Lebanese",
    "Libyan",
    "Lithuanian",
    "Malagasy",
    "Malaysian",
    "Malian",
    "Maltese",
    "Mexican",
    "Mongolian",
    "Moroccan",
    "Mozambican",
    "Namibian",
    "Nepalese",
    "Dutch",
    "New Zealand",
    "Nicaraguan",
    "Nigerian",
    "Norwegian",
    "Pakistani",
    "Panamanian",
    "Paraguayan",
    "Peruvian",
    "Philippine",
    "Polish",
    "Portuguese",
    "Romanian",
    "Russian",
    "Saudi",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Singaporean",
    "Slovak",
    "South African",
    "Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajikistani",
    "Thai",
    "Tongan",
    "Tunisian",
    "Turkish",
    "Ukrainian",
    "Emirati",
    "British",
    "American",
    "Uruguayan",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Zambia"]
}
const getGdprSensitiveFields = () => {
  return [
    "contract",
    "education",
    "telephone",
    "address",
    "city",
    "postalCode",
    "personalId",
    "email",
    "category",
    "birthdate",
    "idCard",
    "equipment",
    "tshirtSize",
    "tshirt",
    "longTshirt",
    "vest",
    "shoeSize",
    "workShoes",
    "bag",
  ]
}

const formatDate = date => {
  return date === null || date === undefined ? '' : moment(date).format('DD.MM.YYYY')
}

const formatTime = date => {
  return date === null || date === undefined ? '' : moment(date).format('HH:mm')
}

const formatDateTimeToSend = date => {
  return date === null || date === undefined ? '' : date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + 'T' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
}

const priceFormat = (num, decimal = 0) => {
  if (num) {
    let temp = parseFloat(num)
    if (decimal) {
      temp = temp.toFixed(decimal)
    }
    return temp.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' €'
  }
  if (num === 0) {
    return parseFloat(num).toFixed(decimal).replace('.', ',') + ' €'
  }
  return num
}

const getOrderTotal = (orderItems) => {
  let cartTotal = 0
  if (orderItems && orderItems.length) {
    for (const orderItem of orderItems) {
      cartTotal += orderItem.price * orderItem.quantity
    }
  }
  return cartTotal
}

const isRole = (role) => {
  let userData = getUserData()
  return userData && userData.role === role
}

const isRoleVibrationAdmin = () => {
  let userData = getUserData()
  return isRoleAdmin() && userData.email.includes('@vibration.sk')
}

const isRoleAdmin = () => {
  return isRole('ROLE_ADMIN')
}

const isRoleEmployee = () => {
  return isRole('ROLE_EMPLOYEE')
}

const userCapability = (action, route) => {
  let userData = getUserData()
  if (userData && userData.role === 'ROLE_ADMIN') {
    return true
  }

  if (typeof route === 'object' && route.meta.permission) {
    const splitTo = route.meta.permission.split('.')
    if (route.meta.permission === 'USER.PROFILE') {
      return true
    }
    route = splitTo[0].toUpperCase()
    if (userData.permissions[route] && userData.permissions[route][action]) {
      return true
    }
  }
  if (typeof route === 'string' || route instanceof String) {
    if (userData.permissions[route] && userData.permissions[route][action]) {
      return true
    }
  }

  return false
}
const userAdditionalCapability = (action) => {
  let userData = getUserData()
  if (userData.role === 'ROLE_ADMIN') {
    return true
  }
  if (userData.additionalPermissions[action].value) {
    return true
  }
  return false
}

const getItemSelectOption = (item) => {
  let name = [
    item.number,
    item.crossReference,
    item.name,
  ].join(' ').trim()

  return {
    label: name,
    id: item['@id'],
    numberId: item.id,
    fileUrl: item.file ? item.file.url : null,
  }
}

const getOrderStatusBadgeClass = (status) => {
    if (['NEW','PENDING','APPROVED_BY_MANAGER','SENT','WAITING_FOR_INVOICE','INVOICE_PAID','DONE',].includes(status)) {
      return status.toLowerCase()
    }
    return 'primary'
}

const hasQueryParameter = ($route, paramName) => {
  const query = JSON.parse(JSON.stringify($route.query));
  return typeof query[paramName] !== 'undefined'
}

const hasAnyOfQueryParameter = ($route, paramNames) => {
  let found = false

  const query = JSON.parse(JSON.stringify($route.query));

  paramNames.forEach((paramName) => {
    if (false === found) {
      found = typeof query[paramName] !== 'undefined'
    }
  })

  return found
}

const findElementOptionInList = (list, value) => {
  let found = {}

  if (typeof list === 'undefined') {
    return found
  }

  list.forEach(option => {
    if (option.id && option.id === value) {
      found = {
        id: option.id,
        label: option.label
      }
    }
  })

  return found
}

const getItemEditLink = (item) => {
  if (typeof item.itemCategory  === 'undefined') {
    return '#'
  }

  let link = ''
  if (item.itemCategory === 'SPARE_PART') {
    link = 'spare-parts'
  } else if (item.itemCategory === 'MAINTENANCE') {
    link = 'maintenances'
  } else if (item.itemCategory === 'ITEMS') {
    link = 'items'
  } else if (item.itemCategory === 'IT') {
    link = 'it'
  } else if (item.itemCategory === 'LAY_BOARD') {
    link = 'layboards'
  } else if (item.itemCategory === 'TOOL') {
    link = 'tools'
  } else if (item.itemCategory === 'TERMINAL_REVIEW') {
    link = 'terminal-reviews'
  } else if (item.itemCategory === 'EXTRACTION') {
    link = 'extractions'
  } else if (item.itemCategory === 'TIGHTENING_TORQUES') {
    link = 'tightening-torques'
  } else if (item.itemCategory === 'NAVISION_ITEM') {
    link = 'navision-items'
  } else if (item.itemCategory === 'OFFICE') {
    link = 'offices'
  } else if (item.itemCategory === 'EQUIPMENT') {
    link = 'equipments'
  } else if (item.itemCategory === 'OTHER') {
    link = 'others'
  }

  return getEditUrl(link, item.id)
}

const truncate = (text, length, suffix) => {
  const defLength = 30

  if (typeof text === 'undefined' || text.length === 0 || defLength > text.length) {
    return text
  }

  if (typeof suffix === 'undefined') {
    suffix = '...'
  }

  return text.slice(0, length || defLength) + suffix
}

export default {
  slugify,
  slugifyURL,
  showToast,
  getEnumTranslation,
  generateRandomString,
  formatDate,
  formatTime,
  formatDateTimeToSend,
  getAddUrl,
  getDetailUrl,
  getEditUrl,
  getViewUrl,
  getCountriesList,
  priceFormat,
  getOrderTotal,
  userCapability,
  userAdditionalCapability,
  isRole,
  isRoleAdmin,
  isRoleEmployee,
  isRoleVibrationAdmin,
  getGdprSensitiveFields,
  getItemSelectOption,
  getOrderStatusBadgeClass,
  hasQueryParameter,
  findElementOptionInList,
  hasAnyOfQueryParameter,
  getItemEditLink,
  truncate
}
