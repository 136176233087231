<template>
  <div :class="{ active: navbarActive }" id="main-navigation">
    <a href="javascript:void(0)" class="navbar-close" @click="closeNav">
      <BootstrapIcon icon="x-lg" size="1x" />
    </a>
    <router-link to="/" class="logo">
      <img src="@/assets/images/logo.svg" alt="logo" />
    </router-link>
    <div class="navigation-container">
      <!-- Main navigation links -->
      <nav id="navigation-links">
        <router-link v-if="$helper.userCapability('LIST','EMPLOYEES')" to="/employees" @click="closeNav">
          <BootstrapIcon icon="person" size="1x" />
          {{ $t("navigations.employees") }}
        </router-link>
        <div class="group">
          <router-link to="#" @click="showGroup('items')">
            <BootstrapIcon icon="file-earmark-text" size="1x" />
            {{ $t("navigations.items") }}
          </router-link>
          <div :class="groupActive.items ? 'group__sub active' : 'group__sub'">
            <router-link v-if="$helper.userCapability('LIST','TOOLS')" to="/tools">
              {{ $t("navigations.tools") }}
            </router-link>
            <router-link v-if="$helper.userCapability('LIST','IT')" to="/it">
              {{ $t("navigations.it") }}
            </router-link>
            <router-link v-if="$helper.userCapability('LIST','SPARE_PARTS')" to="/spare-parts">
              {{ $t("navigations.spareParts") }}
            </router-link>
            <router-link v-if="$helper.userCapability('LIST','EXTRACTIONS')" to="/extractions">
              {{ $t("navigations.extractions") }}
            </router-link>
            <router-link v-if="$helper.userCapability('LIST','NAVISITON_ITEMS')" to="/navision-items">
              {{ $t("navigations.navisionItems") }}
            </router-link>
            <router-link v-if="$helper.userCapability('LIST','TIGHTENING_TORQUES')" to="/tightening-torques">
              {{ $t("navigations.tighteningTorques") }}
            </router-link>
            <router-link v-if="$helper.userCapability('LIST','TERMINAL_REVIEWS')"  to="/terminal-reviews">
              {{ $t("navigations.terminalReviews") }}
            </router-link>
            <router-link v-if="$helper.userCapability('LIST','LAYBOARDS')"  to="/layboards">
              {{ $t("navigations.layboards") }}
            </router-link>
            <router-link v-if="$helper.userCapability('LIST','EQUIPMENTS')"  to="/equipments">
              {{ $t("navigations.equipments") }}
            </router-link>
            <router-link v-if="$helper.userCapability('LIST','OTHERS')"  to="/others">
              {{ $t("navigations.others") }}
            </router-link>
          </div>
        </div>

        <router-link v-if="$helper.userCapability('LIST','ORDERS')"  to="/items/to-order" @click="closeNav">
          <BootstrapIcon icon="exclamation-triangle" size="1x" />
          {{ $t("navigations.itemsToOrder") }}
        </router-link>
        <router-link v-if="$helper.userCapability('LIST','VENDORS')"  to="/vendors" @click="closeNav">
          <BootstrapIcon icon="briefcase" size="1x" />
          {{ $t("navigations.vendors") }}
        </router-link>
        <router-link v-if="$helper.userCapability('LIST','ORDERS')"  to="/orders" @click="closeNav">
          <BootstrapIcon icon="bag" size="1x" />
          {{ $t("navigations.orders") }}
        </router-link>
        <router-link v-if="$helper.userCapability('LIST','PRINT')" to="/print" @click="closeNav">
          <BootstrapIcon icon="printer" size="1x" />
          {{ $t("navigations.print") }}
        </router-link>
        <div class="group" v-if="$helper.userCapability('LIST','SKILLS')">
          <a href="javascript:void(0)" @click="showGroup('skills')">
            <BootstrapIcon icon="file-earmark-check" size="1x" />
            {{ $t("navigations.skills") }}
          </a>
          <div :class="groupActive.skills ? 'group__sub active' : 'group__sub'">
            <router-link to="/skills">
              {{ $t("navigations.skills") }}
            </router-link>
            <router-link to="/skill-matrix">
              {{ $t("navigations.skillMatrix") }}
            </router-link>

          </div>
        </div>
        <div class="separator"></div>
        <router-link v-if="$helper.userCapability('CREATE','EXPORTS')" to="/exports" @click="closeNav">
          <bootstrap-icon
              icon="file-arrow-down"
              size="1x"
          />
          {{ $t('navigations.exports') }}
        </router-link>
        <div class="separator"></div>
        <router-link v-if="$helper.userCapability('LIST','EMAILS')" to="/emails" @click="closeNav">
          <BootstrapIcon
              icon="envelope-open"
              size="1x"
          />
          {{ $t('navigations.emails') }}
        </router-link>
        <router-link v-if="$helper.userCapability('LIST','EMAIL_TEMPLATES')" to="/email/templates" @click="closeNav">
          <BootstrapIcon
              icon="envelope"
              size="1x"
          />
          {{ $t('navigations.emailTemplates') }}
        </router-link>
        <div class="separator"></div>
        <div class="group" v-if="$helper.userCapability('LIST','SETTINGS')">
          <a href="javascript:void(0)" @click="showGroup('settings')">
            <BootstrapIcon icon="gear" size="1x" />
            {{ $t("navigations.settings") }}
          </a>
          <div
            :class="groupActive.settings ? 'group__sub active' : 'group__sub'"
          >
            <router-link to="/settings/contracts">
              {{ $t("navigations.contracts") }}
            </router-link>
            <router-link to="/settings/jobs">
              {{ $t("navigations.jobs") }}
            </router-link>
            <router-link to="/settings/employee-types">
              {{ $t("navigations.employeeTypes") }}
            </router-link>
            <router-link to="/settings/departments">
              {{ $t("navigations.departments") }}
            </router-link>
            <router-link to="/settings/locations">
              {{ $t("navigations.locations") }}
            </router-link>
            <router-link to="/settings/navision-types">
              {{ $t("navigations.navisionTypes") }}
            </router-link>
            <router-link to="/settings/types">
              {{ $t("navigations.types") }}
            </router-link>
            <router-link to="/settings/maintenance-types">
              {{ $t("navigations.maintenanceTypes") }}
            </router-link>
            <router-link to="/settings/tshirt-sizes">
              {{ $t("navigations.tshirtSizes") }}
            </router-link>
            <router-link to="/settings/statuses">
              {{ $t("navigations.statuses") }}
            </router-link>
            <router-link to="/settings/skill-types">
              {{ $t("navigations.skillTypes") }}
            </router-link>
            <router-link to="/settings/item-categories">
              {{ $t("navigations.itemCategories") }}
            </router-link>
            <router-link to="/settings/shifts">
              {{ $t("navigations.shifts") }}
            </router-link>
            <router-link to="/roles">
              {{ $t("navigations.roles") }}
            </router-link>
          </div>
        </div>
        <router-link to="/logout" @click="closeNav">
          <BootstrapIcon icon="box-arrow-right" size="1x" />
          {{ $t("navigations.logout") }}
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    navbarActive: { type: Boolean, default: false },
  },
  data() {
    return {
      groupActive: {
        items: false,
        settings: false,
      },
    };
  },
  methods: {
    closeNav() {
      this.$emit("navToggle", false);
      for (const prop in this.groupActive) {
        // skip loop if the property is from prototype
        this.groupActive[prop] = false;
      }
    },
    showGroup(name) {
      this.groupActive[name] = !this.groupActive[name];
    },
    getActiveClass() {
      let className = "";
      if (this.navbarActive === true) {
        className = "active";
      }
      return className;
    },
  },
};
</script>
