import Resource from './Resource'

const apiURL = `https://${process.env.VUE_APP_API_URL}`
let repositories = {}

export default {
  init: JWT => {
    repositories = {
      AcTypes: new Resource(JWT, {apiURL, resource: 'ac_types'}),
      Branches: new Resource(JWT, {apiURL, resource: 'branches'}),
      CsrStamps: new Resource(JWT, {apiURL, resource: 'csr_stamps'}),
      Cwos: new Resource(JWT, {apiURL, resource: 'cwos'}),
      DeliveryItems: new Resource(JWT, {apiURL, resource: 'delivery_items'}),
      UsedItems: new Resource(JWT, {apiURL, resource: 'used_items'}),
      Departments: new Resource(JWT, {apiURL, resource: 'departments'}),
      Enums: new Resource(JWT, {apiURL, resource: 'enums'}),
      Files: new Resource(JWT, {apiURL, resource: 'files'}),
      Forms: new Resource(JWT, {apiURL, resource: 'forms'}),
      FormTypes: new Resource(JWT, {apiURL, resource: 'form_types'}),
      HolidayRequests: new Resource(JWT, {apiURL, resource: 'holiday_requests'}),
      Ocrs: new Resource(JWT, {apiURL, resource: 'ocrs'}),
      Receipts: new Resource(JWT, {apiURL, resource: 'receipts'}),
      Stations: new Resource(JWT, {apiURL, resource: 'stations'}),
      StockItems: new Resource(JWT, {apiURL, resource: 'stock_items'}),
      StockItemLocations: new Resource(JWT, {apiURL, resource: 'stock_item_locations'}),
      StockItemOwners: new Resource(JWT, {apiURL, resource: 'stock_item_owners'}),
      StockItemTypes: new Resource(JWT, {apiURL, resource: 'stock_item_types'}),
      ToolRequests: new Resource(JWT, {apiURL, resource: 'tool_requests'}),
      TravellingOrders: new Resource(JWT, {apiURL, resource: 'travelling_orders'}),
      Users: new Resource(JWT, {apiURL, resource: 'users'}),
      PartRequests: new Resource(JWT, {apiURL, resource: 'cwo_part_requests'}),
      EmailTemplates: new Resource(JWT, {apiURL, resource: 'email_templates'}),
      Emails: new Resource(JWT, {apiURL, resource: 'emails'}),
      Directories: new Resource(JWT, {apiURL, resource: 'directories'}),
      Documents: new Resource(JWT, {apiURL, resource: 'documents'}),
      ReadAndUnderstand: new Resource(JWT, {apiURL, resource: 'read_and_understands'}),
      ServiceRequestTypes: new Resource(JWT, {apiURL, resource: 'service_request_types'}),
      Pdfs: new Resource(JWT, {apiURL, resource: 'pdfs'}),
      Projects: new Resource(JWT, {apiURL, resource: 'projects'}),
      TimeTracks: new Resource(JWT, {apiURL, resource: 'time_tracks'}),
      Skills: new Resource(JWT, { apiURL, resource: 'skills' }),
      SkillMatrices: new Resource(JWT, { apiURL, resource: 'skill_matrices' }),
      EntityOptions: new Resource(JWT, { apiURL, resource: 'entity_options' }),
      Items: new Resource(JWT, { apiURL, resource: 'items' }),
      ItemHistories: new Resource(JWT, { apiURL, resource: 'item_histories' }),
      Vendors: new Resource(JWT, { apiURL, resource: 'vendors' }),
      Orders: new Resource(JWT, { apiURL, resource: 'orders' }),
      PrintHistories: new Resource(JWT, { apiURL, resource: 'print_histories' }),
      Exports: new Resource(JWT, { apiURL, resource: 'exports' }),
      Roles: new Resource(JWT, { apiURL, resource: 'roles' }),
    }
  },
  get: name => repositories[name],
}
