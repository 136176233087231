import JWT from './JWT'
import store from './../store'
import moment from 'moment'

export const isUserLoggedIn = () => localStorage.getItem('userData') && localStorage.getItem(JWT.config.storageTokenKeyName)

export const refreshUserData = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))

  const now = moment()
  if (!userData || (userData.refreshedAt && moment.duration(now.diff(moment(userData.refreshedAt))).asSeconds() < 5) || userData.refreshing ) {
    return
  }

  userData.refreshing = true
  localStorage.setItem('userData', JSON.stringify(userData))

  JWT.axiosIns
      .get(`${store.state.apiUrl}${userData['@id']}`)
      .then((response) => {
            const userData = response.data
            userData.refreshedAt = moment()
            userData.refreshing = false
            localStorage.setItem('userData', JSON.stringify(userData))
      })
}

export const getUserData = () => {
    refreshUserData()
    return JSON.parse(localStorage.getItem('userData'))
}

export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'ROLE_ADMIN') return `/`
  if (userRole === 'ROLE_USER') return `/`
  if (userRole === 'ROLE_EMPLOYEE') return `/`
  return { name: 'login' }
}