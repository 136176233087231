import {createRouter, createWebHistory} from 'vue-router'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'

const routes = [
  // Logout
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/Logout.vue'),
    meta: {
      pageTitle: 'navigations.logout',
      layout: 'full',
    },
  },
  // Logout End
  // Login
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      pageTitle: 'navigations.login',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // Login End
  // Forgot Password
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      pageTitle: 'navigations.forgotPassword',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // Forgot Password End
  // Employees
  {
    path: '/employees',
    name: 'Employees',
    component: () => import('@/views/Admin/Employees/list.vue'),
    meta: {
      permission: 'EMPLOYEES.LIST',
      pageTitle: 'navigations.employees',
      breadcrumb: [
        {
          text: 'navigations.employees',
          active: true,
        },
      ],
    },
  },
  {
    path: '/employees/add',
    name: 'AddEmployee',
    component: () => import('@/views/Admin/Employees/add.vue'),
    meta: {
      permission: 'EMPLOYEES.CRETE',
      pageTitle: 'messages.employeeCreate',
      breadcrumb: [
        {
          text: 'messages.employeeCreate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/employees/:id/edit',
    name: 'EditEmployee',
    component: () => import('@/views/Admin/Employees/edit.vue'),
    meta: {
      permission: 'EMPLOYEES.UPDATE',
      pageTitle: 'messages.employeeUpdate',
      breadcrumb: [
        {
          text: 'messages.employeeUpdate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/employees/:id/view',
    name: 'ViewEmployee',
    component: () => import('@/views/Admin/Employees/edit.vue'),
    meta: {
      permission: 'EMPLOYEES.VIEW',
      pageTitle: 'messages.employeeDetail',
      breadcrumb: [
        {
          text: 'messages.employeeDetail',
          active: true,
        },
      ],
    },
  },
  // Employees End
  // Administrators
  {
    path: '/administrators',
    name: 'Administrators',
    component: () => import('@/views/Admin/Administrators/list.vue'),
    meta: {
      permission: 'EMPLOYEES.LIST',
      pageTitle: 'navigations.administrators',
      breadcrumb: [
        {
          text: 'navigations.administrators',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrators/add',
    name: 'AddAdministrator',
    component: () => import('@/views/Admin/Administrators/add.vue'),
    meta: {
      permission: 'ROLE_ADMIN',
      pageTitle: 'messages.administratorCreate',
      breadcrumb: [
        {
          text: 'messages.administratorCreate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrators/:id/edit',
    name: 'EditAdministrator',
    component: () => import('@/views/Admin/Administrators/edit.vue'),
    meta: {
      permission: 'ROLE_ADMIN',
      pageTitle: 'messages.administratorUpdate',
      breadcrumb: [
        {
          text: 'messages.administratorUpdate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrators/:id/view',
    name: 'ViewAdministrator',
    component: () => import('@/views/Admin/Administrators/edit.vue'),
    meta: {
      permission: 'ROLE_ADMIN',
      pageTitle: 'messages.administratorDetail',
      breadcrumb: [
        {
          text: 'messages.administratorDetail',
          active: true,
        },
      ],
    },
  },
  // Administrators End
    // Items start
    {
        path: '/items',
        name: 'Items',
        component: () => import('@/views/Admin/Items/list.vue'),
        meta: {
            permission: 'ITEMS.LIST',
            pageTitle: 'navigations.items',
            breadcrumb: [
                {
                    text: 'navigations.items',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/items/add',
        name: 'AddItem',
        component: () => import('@/views/Admin/Items/add.vue'),
        meta: {
            permission: 'EMPLOYEES.CREATE',
            pageTitle: 'messages.itemCreate',
            breadcrumb: [
                {
                    text: 'messages.itemCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/items/:id/edit',
        name: 'EditItem',
        component: () => import('@/views/Admin/Items/edit.vue'),
        meta: {
            permission: 'EMPLOYEES.UPDATE',
            pageTitle: 'messages.itemUpdate',
            breadcrumb: [
                {
                    text: 'messages.itemUpdate',
                    active: true,
                },
            ],
        },
    },
    // Items End
    // Other items start
    {
        path: '/others',
        name: 'Others',
        component: () => import('@/views/Admin/Items/Others/list.vue'),
        meta: {
            permission: 'ITEMS.LIST',
            pageTitle: 'navigations.others',
            breadcrumb: [
                {
                    text: 'navigations.others',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/others/add',
        name: 'AddOtherItem',
        component: () => import('@/views/Admin/Items/Others/add.vue'),
        meta: {
            permission: 'EMPLOYEES.CREATE',
            pageTitle: 'messages.itemCreate',
            breadcrumb: [
                {
                    text: 'messages.itemCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/others/:id/edit',
        name: 'EditOtherItem',
        component: () => import('@/views/Admin/Items/Others/edit.vue'),
        meta: {
            permission: 'EMPLOYEES.UPDATE',
            pageTitle: 'messages.itemUpdate',
            breadcrumb: [
                {
                    text: 'messages.itemUpdate',
                    active: true,
                },
            ],
        },
    },
    // Other items End
    // Items To Order Start
    {
        path: '/items/to-order',
        name: 'ItemsToOrder',
        component: () => import('@/views/Admin/ItemsToOrder/list.vue'),
        meta: {
            permission: 'ORDERS.CRAETE',
            pageTitle: 'navigations.itemsToOrder',
            breadcrumb: [
                {
                    text: 'navigations.itemsToOrder',
                    active: true,
                },
            ],
        },
    },
    // Items To Order End
    // Orders Start
    {
        path: '/orders',
        name: 'Orders',
        component: () => import('@/views/Admin/Orders/list.vue'),
        meta: {
            permission: 'ORDERS.LIST',
            pageTitle: 'navigations.orders',
            breadcrumb: [
                {
                    text: 'navigations.orders',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/orders/add',
        name: 'CreateOrder',
        component: () => import('@/views/Admin/Orders/add.vue'),
        meta: {
            permission: 'ORDERS.CREATE',
            pageTitle: 'navigations.orderCreate',
            breadcrumb: [
                {
                    text: 'navigations.orderCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/orders/:id/edit',
        name: 'EditOrder',
        component: () => import('@/views/Admin/Orders/edit.vue'),
        meta: {
            permission: 'ORDERS.UPDATE',
            pageTitle: 'navigations.orderUpdate',
            breadcrumb: [
                {
                    text: 'navigations.orderUpdate',
                    active: true,
                },
            ],
        },
    },
    // Orders End
    // Items Tightening Torques start
    {
        path: '/tightening-torques',
        name: 'TighteningTorques',
        component: () => import('@/views/Admin/Items/TighteningTorques/list.vue'),
        meta: {
            permission: 'TIGHTENING_TORQUES.LIST',
            pageTitle: 'navigations.tighteningTorques',
            breadcrumb: [
                {
                    text: 'navigations.tighteningTorques',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/tightening-torques/add',
        name: 'AddTighteningTorque',
        component: () => import('@/views/Admin/Items/TighteningTorques/add.vue'),
        meta: {
            permission: 'TIGHTENING_TORQUES.CREATE',
            pageTitle: 'messages.tighteningCreate',
            breadcrumb: [
                {
                    text: 'messages.tighteningCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/tightening-torques/:id/edit',
        name: 'EditTighteningTorque',
        component: () => import('@/views/Admin/Items/TighteningTorques/edit.vue'),
        meta: {
            permission: 'TIGHTENING_TORQUES.UPDATE',
            pageTitle: 'messages.tighteningUpdate',
            breadcrumb: [
                {
                    text: 'messages.tighteningUpdate',
                    active: true,
                },
            ],
        },
    },
    // Items Tightening Torques End
    // Items Tools start
    {
        path: '/tools',
        name: 'Tools',
        component: () => import('@/views/Admin/Items/Tools/list.vue'),
        meta: {
            permission: 'TOOLS.LIST',
            pageTitle: 'navigations.tools',
            breadcrumb: [
                {
                    text: 'navigations.tools',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/tools/add',
        name: 'AddTool',
        component: () => import('@/views/Admin/Items/Tools/add.vue'),
        meta: {
            permission: 'TOOLS.CREATE',
            pageTitle: 'messages.toolCreate',
            breadcrumb: [
                {
                    text: 'messages.toolCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/tools/:id/edit',
        name: 'EditTool',
        component: () => import('@/views/Admin/Items/Tools/edit.vue'),
        meta: {
            permission: 'TOOLS.UPDATE',
            pageTitle: 'messages.toolUpdate',
            breadcrumb: [
                {
                    text: 'messages.toolUpdate',
                    active: true,
                },
            ],
        },
    },
    // Items Tools End
    // Items IT start
    {
        path: '/it',
        name: 'IT',
        component: () => import('@/views/Admin/Items/It/list.vue'),
        meta: {
            permission: 'IT.LIST',
            pageTitle: 'navigations.it',
            breadcrumb: [
                {
                    text: 'navigations.it',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/it/add',
        name: 'AddIt',
        component: () => import('@/views/Admin/Items/It/add.vue'),
        meta: {
            permission: 'IT.CREATE',
            pageTitle: 'messages.itCreate',
            breadcrumb: [
                {
                    text: 'messages.itCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/it/:id/edit',
        name: 'EditIt',
        component: () => import('@/views/Admin/Items/It/edit.vue'),
        meta: {
            permission: 'IT.UPDATE',
            pageTitle: 'messages.itUpdate',
            breadcrumb: [
                {
                    text: 'messages.itUpdate',
                    active: true,
                },
            ],
        },
    },
    // Items IT End
    // Items Spare Parts start
    {
        path: '/spare-parts',
        name: 'SpareParts',
        component: () => import('@/views/Admin/Items/SpareParts/list.vue'),
        meta: {
            permission: 'SPARE_PARTS.LIST',
            pageTitle: 'navigations.spareParts',
            breadcrumb: [
                {
                    text: 'navigations.spareParts',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/spare-parts/add',
        name: 'AddSparePart',
        component: () => import('@/views/Admin/Items/SpareParts/add.vue'),
        meta: {
            permission: 'SPARE_PARTS.CREATE',
            pageTitle: 'messages.sparePartCreate',
            breadcrumb: [
                {
                    text: 'messages.sparePartCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/spare-parts/:id/edit',
        name: 'EditSparePart',
        component: () => import('@/views/Admin/Items/SpareParts/edit.vue'),
        meta: {
            permission: 'SPARE_PARTS.UPDATE',
            pageTitle: 'messages.sparePartUpdate',
            breadcrumb: [
                {
                    text: 'messages.sparePartUpdate',
                    active: true,
                },
            ],
        },
    },
    // Items Spare Parts End
    // Items Equipments start
    {
        path: '/equipments',
        name: 'Equipments',
        component: () => import('@/views/Admin/Items/Equipments/list.vue'),
        meta: {
            permission: 'EQUIPMENTS.LIST',
            pageTitle: 'navigations.equipments',
            breadcrumb: [
                {
                    text: 'navigations.equipments',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/equipments/add',
        name: 'AddEquipment',
        component: () => import('@/views/Admin/Items/Equipments/add.vue'),
        meta: {
            permission: 'EQUIPMENTS.CREATE',
            pageTitle: 'messages.equipmentCreate',
            breadcrumb: [
                {
                    text: 'messages.equipmentCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/equipments/:id/edit',
        name: 'EditEquipment',
        component: () => import('@/views/Admin/Items/Equipments/edit.vue'),
        meta: {
            permission: 'EQUIPMENTS.UPDATE',
            pageTitle: 'messages.equipmentUpdate',
            breadcrumb: [
                {
                    text: 'messages.equipmentsUpdate',
                    active: true,
                },
            ],
        },
    },
    // Items Equipments End
    // Items Extractions start
    {
        path: '/extractions',
        name: 'Extractions',
        component: () => import('@/views/Admin/Items/Extractions/list.vue'),
        meta: {
            permission: 'EXTRACTIONS.LIST',
            pageTitle: 'navigations.extractions',
            breadcrumb: [
                {
                    text: 'navigations.extractions',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/extractions/add',
        name: 'AddExtraction',
        component: () => import('@/views/Admin/Items/Extractions/add.vue'),
        meta: {
            permission: 'EXTRACTIONS.CREATE',
            pageTitle: 'messages.extractionCreate',
            breadcrumb: [
                {
                    text: 'messages.extractionCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/extractions/:id/edit',
        name: 'EditExtraction',
        component: () => import('@/views/Admin/Items/Extractions/edit.vue'),
        meta: {
            permission: 'EXTRACTIONS.UPDATE',
            pageTitle: 'messages.extractionUpdate',
            breadcrumb: [
                {
                    text: 'messages.extractionUpdate',
                    active: true,
                },
            ],
        },
    },
    // Items Extractions End
    // Items Navision Items start
    {
        path: '/navision-items',
        name: 'NavisionItems',
        component: () => import('@/views/Admin/Items/NavisionItems/list.vue'),
        meta: {
            permission: 'NAVISITON_ITEMS.LIST',
            pageTitle: 'navigations.navisionItems',
            breadcrumb: [
                {
                    text: 'navigations.navisionItems',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/navision-items/add',
        name: 'AddNavisionItem',
        component: () => import('@/views/Admin/Items/NavisionItems/add.vue'),
        meta: {
            permission: 'NAVISITON_ITEMS.CREATE',
            pageTitle: 'messages.navisionItemCreate',
            breadcrumb: [
                {
                    text: 'messages.navisionItemCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/navision-items/:id/edit',
        name: 'EditNavisionItem',
        component: () => import('@/views/Admin/Items/NavisionItems/edit.vue'),
        meta: {
            permission: 'NAVISITON_ITEMS.UPDATE',
            pageTitle: 'messages.navisionItemUpdate',
            breadcrumb: [
                {
                    text: 'messages.navisionItemUpdate',
                    active: true,
                },
            ],
        },
    },
    // Items Navision Items End
    // Items Terminal Reviews start
    {
        path: '/terminal-reviews',
        name: 'TerminalReviews',
        component: () => import('@/views/Admin/Items/TerminalReview/list.vue'),
        meta: {
            permission: 'TERMINAL_REVIEWS.LIST',
            pageTitle: 'navigations.terminalReviews',
            breadcrumb: [
                {
                    text: 'navigations.terminalReviews',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/terminal-reviews/add',
        name: 'AddTerminalReview',
        component: () => import('@/views/Admin/Items/TerminalReview/add.vue'),
        meta: {
            permission: 'TERMINAL_REVIEWS.CREATE',
            pageTitle: 'messages.terminalReviewCreate',
            breadcrumb: [
                {
                    text: 'messages.terminalReviewCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/terminal-reviews/:id/edit',
        name: 'EditTerminalReview',
        component: () => import('@/views/Admin/Items/TerminalReview/edit.vue'),
        meta: {
            permission: 'TERMINAL_REVIEWS.UPDATE',
            pageTitle: 'messages.terminalReviewUpdate',
            breadcrumb: [
                {
                    text: 'messages.terminalReviewUpdate',
                    active: true,
                },
            ],
        },
    },
    // Items Terminal Reviews End
    // Items Layboards start
    {
        path: '/layboards',
        name: 'Layboards',
        component: () => import('@/views/Admin/Items/Layboards/list.vue'),
        meta: {
            permission: 'LAYBOARDS.LIST',
            pageTitle: 'navigations.layboard',
            breadcrumb: [
                {
                    text: 'navigations.layboard',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/layboards/add',
        name: 'AddLayboard',
        component: () => import('@/views/Admin/Items/Layboards/add.vue'),
        meta: {
            permission: 'LAYBOARDS.CREATE',
            pageTitle: 'messages.layboardCreate',
            breadcrumb: [
                {
                    text: 'messages.layboardCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/layboards/:id/edit',
        name: 'EditLayboard',
        component: () => import('@/views/Admin/Items/Layboards/edit.vue'),
        meta: {
            permission: 'LAYBOARDS.UPDATE',
            pageTitle: 'messages.layboardUpdate',
            breadcrumb: [
                {
                    text: 'messages.layboardUpdate',
                    active: true,
                },
            ],
        },
    },
    // Items Layboards End
   // Skill matrix
   {
    path: '/skill-matrix',
    name: 'Skill matrix',
    component: () => import('@/views/Admin/Skills/SkillMatrix/list.vue'),
    meta: {
        permission: 'SKILLS.LIST',
        pageTitle: 'navigations.skillMatrix',
      breadcrumb: [
        {
          text: 'navigations.skillMatrix',
          active: true,
        },
      ],
    },
  },
  {
    path: '/skill-matrix/add',
    name: 'AddSkillMatrix',
    component: () => import('@/views/Admin/Skills/SkillMatrix/add.vue'),
    meta: {
        permission: 'SKILLS.CREATE',
        pageTitle: 'messages.skillMatrixCreate',
      breadcrumb: [
        {
          text: 'messages.skillMatrixCreate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/skill-matrix/:employeeId/add',
    name: 'AddSkillMatrixForEmployee',
    component: () => import('@/views/Admin/Skills/SkillMatrix/add.vue'),
    meta: {
        permission: 'SKILLS.CREATE',
        pageTitle: 'messages.skillMatrixCreate',
      breadcrumb: [
        {
          text: 'messages.skillMatrixCreate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/skill-matrix/:id/edit',
    name: 'EditSkillMatrix',
    component: () => import('@/views/Admin/Skills/SkillMatrix/edit.vue'),
    meta: {
        permission: 'SKILLS.UPDATE',
        pageTitle: 'messages.skillMatrixUpdate',
      breadcrumb: [
        {
          text: 'messages.skillMatrixUpdate',
          active: true,
        },
      ],
    },

  },
  // Skill matrix End
  // Skills
  {
    path: '/skills',
    name: 'Skills',
    component: () => import('@/views/Admin/Skills/list.vue'),
    meta: {
        permission: 'SKILLS.LIST',
        pageTitle: 'navigations.skills',
      breadcrumb: [
        {
          text: 'navigations.skills',
          active: true,
        },
      ],
    },
  },
  {
    path: '/skills/add',
    name: 'AddSkill',
    component: () => import('@/views/Admin/Skills/add.vue'),
    meta: {
        permission: 'SKILLS.CREATE',
        pageTitle: 'messages.skillCreate',
      breadcrumb: [
        {
          text: 'messages.skillCreate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/skills/:id/edit',
    name: 'EditSkill',
    component: () => import('@/views/Admin/Skills/edit.vue'),
    meta: {
        permission: 'SKILLS.UPDATE',
        pageTitle: 'messages.skillsUpdate',
      breadcrumb: [
        {
          text: 'messages.skillsUpdate',
          active: true,
        },
      ],
    },

  },
  // Skill matrix End
  // User profile
  {
    path: '/profile',
    name: 'EditProfile',
    component: () => import('@/views/Admin/UserProfile/edit.vue'),
    meta: {
        permission: 'USER.PROFILE',
        pageTitle: 'messages.userUpdate',
      breadcrumb: [
        {
          text: 'messages.userUpdate',
          active: true,
        },
      ],
    },
  },
  // User profile end
    // Settings Start
    // Settings Contracts Start
    {
        path: '/settings/contracts',
        name: 'Contracts',
        component: () => import('@/views/Admin/Settings/Contracts/list.vue'),
        meta: {
            permission: 'SETTINGS.LIST',
            pageTitle: 'navigations.contracts',
            breadcrumb: [
                {
                    text: 'navigations.contracts',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/contracts/add',
        name: 'AddContract',
        component: () => import('@/views/Admin/Settings/Contracts/add.vue'),
        meta: {
            permission: 'SETTINGS.CREATE',
            pageTitle: 'messages.contractCreate',
            breadcrumb: [
                {
                    text: 'messages.contractCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/contracts/:id/edit',
        name: 'EditContract',
        component: () => import('@/views/Admin/Settings/Contracts/edit.vue'),
        meta: {
            permission: 'SETTINGS.UPDATE',
            pageTitle: 'messages.contractUpdate',
            breadcrumb: [
                {
                    text: 'messages.contractUpdate',
                    active: true,
                },
            ],
        },
    },
    // Settings Contracts End
    // Settings Jobs Start
    {
        path: '/settings/jobs',
        name: 'Jobs',
        component: () => import('@/views/Admin/Settings/Jobs/list.vue'),
        meta: {
            permission: 'SETTINGS.LIST',
            pageTitle: 'navigations.jobs',
            breadcrumb: [
                {
                    text: 'navigations.jobs',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/jobs/add',
        name: 'AddJob',
        component: () => import('@/views/Admin/Settings/Jobs/add.vue'),
        meta: {
            permission: 'SETTINGS.CREATE',
            pageTitle: 'messages.jobCreate',
            breadcrumb: [
                {
                    text: 'messages.jobCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/jobs/:id/edit',
        name: 'EditJob',
        component: () => import('@/views/Admin/Settings/Jobs/edit.vue'),
        meta: {
            permission: 'SETTINGS.UPDATE',
            pageTitle: 'messages.jobUpdate',
            breadcrumb: [
                {
                    text: 'messages.jobUpdate',
                    active: true,
                },
            ],
        },
    },
    // Settings Jobs End
    // Settings Employee Types Start
    {
        path: '/settings/employee-types',
        name: 'EmployeeTypes',
        component: () => import('@/views/Admin/Settings/EmployeeTypes/list.vue'),
        meta: {
            permission: 'SETTINGS.LIST',
            pageTitle: 'navigations.employeeTypes',
            breadcrumb: [
                {
                    text: 'navigations.employeeTypes',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/employee-types/add',
        name: 'AddEmployeeType',
        component: () => import('@/views/Admin/Settings/EmployeeTypes/add.vue'),
        meta: {
            permission: 'SETTINGS.CREATE',
            pageTitle: 'messages.employeeTypeCreate',
            breadcrumb: [
                {
                    text: 'messages.employeeTypeCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/employee-types/:id/edit',
        name: 'EditEmployeeType',
        component: () => import('@/views/Admin/Settings/EmployeeTypes/edit.vue'),
        meta: {
            permission: 'SETTINGS.UPDATE',
            pageTitle: 'messages.employeeTypeUpdate',
            breadcrumb: [
                {
                    text: 'messages.employeeTypeUpdate',
                    active: true,
                },
            ],
        },
    },
    // Settings Employee Types End
    // Settings Departments Start
    {
        path: '/settings/departments',
        name: 'Departments',
        component: () => import('@/views/Admin/Settings/Departments/list.vue'),
        meta: {
            permission: 'SETTINGS.LIST',
            pageTitle: 'navigations.departments',
            breadcrumb: [
                {
                    text: 'navigations.departments',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/departments/add',
        name: 'AddDepartment',
        component: () => import('@/views/Admin/Settings/Departments/add.vue'),
        meta: {
            permission: 'SETTINGS.CREATE',
            pageTitle: 'messages.departmentCreate',
            breadcrumb: [
                {
                    text: 'messages.departmentCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/departments/:id/edit',
        name: 'EditDepartment',
        component: () => import('@/views/Admin/Settings/Departments/edit.vue'),
        meta: {
            permission: 'SETTINGS.UPDATE',
            pageTitle: 'messages.departmentUpdate',
            breadcrumb: [
                {
                    text: 'messages.departmentUpdate',
                    active: true,
                },
            ],
        },
    },
    // Settings Departments End
    // Settings Locations Start
    {
        path: '/settings/locations',
        name: 'Locations',
        component: () => import('@/views/Admin/Settings/Locations/list.vue'),
        meta: {
            permission: 'SETTINGS.LIST',
            pageTitle: 'navigations.locations',
            breadcrumb: [
                {
                    text: 'navigations.locations',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/locations/add',
        name: 'AddLocation',
        component: () => import('@/views/Admin/Settings/Locations/add.vue'),
        meta: {
            permission: 'SETTINGS.CREATE',
            pageTitle: 'messages.locationCreate',
            breadcrumb: [
                {
                    text: 'messages.locationCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/locations/:id/edit',
        name: 'EditLocation',
        component: () => import('@/views/Admin/Settings/Locations/edit.vue'),
        meta: {
            permission: 'SETTINGS.UPDATE',
            pageTitle: 'messages.locationUpdate',
            breadcrumb: [
                {
                    text: 'messages.locationUpdate',
                    active: true,
                },
            ],
        },
    },
    // Settings Locations End
    // Settings Navision types Start
    {
        path: '/settings/navision-types',
        name: 'NavisionTypes',
        component: () => import('@/views/Admin/Settings/NavisionTypes/list.vue'),
        meta: {
            permission: 'SETTINGS.LIST',
            pageTitle: 'navigations.navisionTypes',
            breadcrumb: [
                {
                    text: 'navigations.navisionTypes',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/navision-types/add',
        name: 'AddNavisionType',
        component: () => import('@/views/Admin/Settings/NavisionTypes/add.vue'),
        meta: {
            permission: 'SETTINGS.CREATE',
            pageTitle: 'messages.navisionTypeCreate',
            breadcrumb: [
                {
                    text: 'messages.navisionTypeCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/navision-types/:id/edit',
        name: 'EditNavisionType',
        component: () => import('@/views/Admin/Settings/NavisionTypes/edit.vue'),
        meta: {
            permission: 'SETTINGS.UPDATE',
            pageTitle: 'messages.navisionTypeUpdate',
            breadcrumb: [
                {
                    text: 'messages.navisionTypeUpdate',
                    active: true,
                },
            ],
        },
    },
    // Settings Navision types End
    // Settings Maintenance types Start
    {
        path: '/settings/maintenance-types',
        name: 'MaintenanceTypes',
        component: () => import('@/views/Admin/Settings/MaintenanceTypes/list.vue'),
        meta: {
            permission: 'SETTINGS.LIST',
            pageTitle: 'navigations.maintenanceTypes',
            breadcrumb: [
                {
                    text: 'navigations.maintenanceTypes',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/maintenance-types/add',
        name: 'AddMaintenanceType',
        component: () => import('@/views/Admin/Settings/MaintenanceTypes/add.vue'),
        meta: {
            permission: 'SETTINGS.CREATE',
            pageTitle: 'messages.maintenanceTypeCreate',
            breadcrumb: [
                {
                    text: 'messages.maintenanceTypeCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/maintenance-types/:id/edit',
        name: 'EditMaintenanceType',
        component: () => import('@/views/Admin/Settings/MaintenanceTypes/edit.vue'),
        meta: {
            permission: 'SETTINGS.UPDATE',
            pageTitle: 'messages.maintenanceTypeUpdate',
            breadcrumb: [
                {
                    text: 'messages.maintenanceTypeUpdate',
                    active: true,
                },
            ],
        },
    },
    // Settings Maintenance types End
    // Settings Types Start
    {
        path: '/settings/types',
        name: 'types',
        component: () => import('@/views/Admin/Settings/Types/list.vue'),
        meta: {
            permission: 'SETTINGS.LIST',
            pageTitle: 'navigations.types',
            breadcrumb: [
                {
                    text: 'navigations.types',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/types/add',
        name: 'AddType',
        component: () => import('@/views/Admin/Settings/Types/add.vue'),
        meta: {
            permission: 'SETTINGS.CREATE',
            pageTitle: 'messages.typeCreate',
            breadcrumb: [
                {
                    text: 'messages.typeCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/types/:id/edit',
        name: 'EditType',
        component: () => import('@/views/Admin/Settings/Types/edit.vue'),
        meta: {
            permission: 'SETTINGS.UPDATE',
            pageTitle: 'messages.typeUpdate',
            breadcrumb: [
                {
                    text: 'messages.typeUpdate',
                    active: true,
                },
            ],
        },
    },
    // Settings Types End
    // Settings Statuses Start
    {
        path: '/settings/statuses',
        name: 'Statuses',
        component: () => import('@/views/Admin/Settings/Statuses/list.vue'),
        meta: {
            permission: 'SETTINGS.LIST',
            pageTitle: 'navigations.statuses',
            breadcrumb: [
                {
                    text: 'navigations.statuses',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/statuses/add',
        name: 'AddStatus',
        component: () => import('@/views/Admin/Settings/Statuses/add.vue'),
        meta: {
            permission: 'SETTINGS.CREATE',
            pageTitle: 'messages.statusCreate',
            breadcrumb: [
                {
                    text: 'messages.statusCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/statuses/:id/edit',
        name: 'EditStatus',
        component: () => import('@/views/Admin/Settings/Statuses/edit.vue'),
        meta: {
            permission: 'SETTINGS.UPDATE',
            pageTitle: 'messages.statusUpdate',
            breadcrumb: [
                {
                    text: 'messages.statusUpdate',
                    active: true,
                },
            ],
        },
    },
    // Settings Statuses End
    // Settings T-Shirt Sizes Start
    {
        path: '/settings/tshirt-sizes',
        name: 'TShirtSizes',
        component: () => import('@/views/Admin/Settings/TShirtSizes/list.vue'),
        meta: {
            permission: 'SETTINGS.LIST',
            pageTitle: 'navigations.tshirtSizes',
            breadcrumb: [
                {
                    text: 'navigations.tshirtSizes',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/tshirt-sizes/add',
        name: 'AddTShirtSize',
        component: () => import('@/views/Admin/Settings/TShirtSizes/add.vue'),
        meta: {
            permission: 'SETTINGS.CREATE',
            pageTitle: 'messages.tshirtSizeCreate',
            breadcrumb: [
                {
                    text: 'messages.tshirtSizeCreateCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/tshirt-sizes/:id/edit',
        name: 'EditTShirtSize',
        component: () => import('@/views/Admin/Settings/TShirtSizes/edit.vue'),
        meta: {
            permission: 'SETTINGS.UPDATE',
            pageTitle: 'messages.tshirtSizeCreateUpdate',
            breadcrumb: [
                {
                    text: 'messages.tshirtSizeCreateUpdate',
                    active: true,
                },
            ],
        },
    },
    // Settings T-Shirt Sizes End
    // Settings Skill Types Start
    {
        path: '/settings/skill-types',
        name: 'SkillTypes',
        component: () => import('@/views/Admin/Settings/SkillTypes/list.vue'),
        meta: {
            permission: 'SETTINGS.LIST',
            pageTitle: 'navigations.skillTypes',
            breadcrumb: [
                {
                    text: 'navigations.skillTypes',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/skill-types/add',
        name: 'addSkillType',
        component: () => import('@/views/Admin/Settings/SkillTypes/add.vue'),
        meta: {
            permission: 'SETTINGS.CREATE',
            pageTitle: 'messages.skillTypeCreate',
            breadcrumb: [
                {
                    text: 'messages.skillTypeCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/skill-types/:id/edit',
        name: 'EditSkillType',
        component: () => import('@/views/Admin/Settings/SkillTypes/edit.vue'),
        meta: {
            permission: 'SETTINGS.UPDATE',
            pageTitle: 'messages.skillTypeUpdate',
            breadcrumb: [
                {
                    text: 'messages.skillTypeUpdate',
                    active: true,
                },
            ],
        },
    },
    // Settings Skill Types End
    // Settings Vendors Start
    {
        path: '/vendors',
        name: 'Vendors',
        component: () => import('@/views/Admin/Vendors/list.vue'),
        meta: {
            permission: 'VENDORS.LIST',
            pageTitle: 'navigations.vendors',
            breadcrumb: [
                {
                    text: 'navigations.vendors',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/vendors/add',
        name: 'addVendor',
        component: () => import('@/views/Admin/Vendors/add.vue'),
        meta: {
            permission: 'VENDORS.CREATE',
            pageTitle: 'messages.vendorCreate',
            breadcrumb: [
                {
                    text: 'messages.vendorCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/vendors/:id/edit',
        name: 'EditVendor',
        component: () => import('@/views/Admin/Vendors/edit.vue'),
        meta: {
            permission: 'VENDORS.UPDATE',
            pageTitle: 'messages.vendorUpdate',
            breadcrumb: [
                {
                    text: 'messages.vendorUpdate',
                    active: true,
                },
            ],
        },
    },
    // Settings Vendors End
    // Settings Item categories Start
    {
        path: '/settings/item-categories',
        name: 'ItemCategory',
        component: () => import('@/views/Admin/Settings/ItemCategories/list.vue'),
        meta: {
            permission: 'SETTINGS.LIST',
            pageTitle: 'navigations.itemCategories',
            breadcrumb: [
                {
                    text: 'navigations.itemCategories',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/item-categories/add',
        name: 'addItemCategory',
        component: () => import('@/views/Admin/Settings/ItemCategories/add.vue'),
        meta: {
            permission: 'SETTINGS.CREATE',
            pageTitle: 'messages.itemCategoryCreate',
            breadcrumb: [
                {
                    text: 'messages.itemCategoryCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/item-categories/:id/edit',
        name: 'EditItemCategory',
        component: () => import('@/views/Admin/Settings/ItemCategories/edit.vue'),
        meta: {
            permission: 'SETTINGS.UPDATE',
            pageTitle: 'messages.itemCategoryUpdate',
            breadcrumb: [
                {
                    text: 'messages.itemCategoryUpdate',
                    active: true,
                },
            ],
        },
    },
    // Settings Item categories End
    // Settings Shifts Start
    {
        path: '/settings/shifts',
        name: 'Shift',
        component: () => import('@/views/Admin/Settings/Shifts/list.vue'),
        meta: {
            permission: 'SETTINGS.LIST',
            pageTitle: 'navigations.shifts',
            breadcrumb: [
                {
                    text: 'navigations.shifts',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/shifts/add',
        name: 'addShift',
        component: () => import('@/views/Admin/Settings/Shifts/add.vue'),
        meta: {
            permission: 'SETTINGS.CREATE',
            pageTitle: 'messages.shiftCreate',
            breadcrumb: [
                {
                    text: 'messages.shiftCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/shifts/:id/edit',
        name: 'EditShift',
        component: () => import('@/views/Admin/Settings/Shifts/edit.vue'),
        meta: {
            permission: 'SETTINGS.UPDATE',
            pageTitle: 'messages.shiftUpdate',
            breadcrumb: [
                {
                    text: 'messages.shiftUpdate',
                    active: true,
                },
            ],
        },
    },
    // Settings Shifts End
    // Settings End
    // Print Start
    {
        path: '/print',
        name: 'Print',
        component: () => import('@/views/Admin/Print/list.vue'),
        meta: {
            pageTitle: 'navigations.print',
            breadcrumb: [
                {
                    text: 'navigations.print',
                    active: true,
                },
            ],
        },
    },
    // Print End
    // Exports
    {
        path: '/exports',
        name: 'exports',
        component: () => import('@/views/Admin/Exports/index.vue'),
        meta: {
            permission: 'EXPORTS.LIST',
            pageTitle: 'navigations.exports',
            breadcrumb: [
                {
                    text: 'navigations.exports',
                    active: true,
                },
            ],
        },
    },
    // Exports End
    // Emails
    {
        path: '/emails',
        name: 'Emails',
        component: () => import('@/views/Admin/Emails/list.vue'),
        meta: {
            permission: 'EMAILS.LIST',
            pageTitle: 'navigations.emails',
            breadcrumb: [
                {
                    text: 'navigations.emails',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/emails/:id',
        name: 'ViewEmails',
        component: () => import('@/views/Admin/Emails/detail.vue'),
        meta: {
            permission: 'EXPORTS.VIEW',
            pageTitle: 'messages.viewEmail',
            breadcrumb: [
                {
                    text: 'messages.viewEmail',
                    active: true,
                },
            ],
        },
    },
    // Emails End
    // Email templates
    {
        path: '/email/templates',
        name: 'EmailTemplates',
        component: () => import('@/views/Admin/EmailTemplates/list.vue'),
        meta: {
            permission: 'EMAIL_TEMPLATES.LIST',
            pageTitle: 'navigations.emailTemplates',
            breadcrumb: [
                {
                    text: 'navigations.emailTemplates',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/email/templates/:id/edit',
        name: 'EditEmailTemplate',
        component: () => import('@/views/Admin/EmailTemplates/edit.vue'),
        meta: {
            permission: 'EMAIL_TEMPLATES.UPDATE',
            pageTitle: 'messages.emailTemplateUpdate',
            breadcrumb: [
                {
                    text: 'messages.emailTemplateUpdate',
                    active: true,
                },
            ],
        },
    },
    // Email template end
    // Roles
    {
        path: '/roles',
        name: 'RolesList',
        component: () => import('@/views/Admin/Roles/list.vue'),
        meta: {
            permission: 'ROLES.LIST',
            pageTitle: 'navigations.roles',
            breadcrumb: [
                {
                    text: 'navigations.roles',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/roles/add',
        name: 'RolesAdd',
        component: () => import('@/views/Admin/Roles/add.vue'),
        meta: {
            permission: 'ROLES.CREATE',
            pageTitle: 'messages.roleCreate',
            breadcrumb: [
                {
                    text: 'messages.roleCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/roles/:id/edit',
        name: 'RoleEdit',
        component: () => import('@/views/Admin/Roles/edit.vue'),
        meta: {
            permission: 'ROLES.UPDATE',
            pageTitle: 'messages.roleUpdate',
            breadcrumb: [
                {
                    text: 'messages.roleUpdate',
                    active: true,
                },
            ],
        },

    },
    // Roles end
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  // Redirect to login if not logged in
  if (!isLoggedIn && (to.name !== 'login' && to.name !== 'ForgotPassword')) {
    return next({name: 'login'})
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.roles[0] : null))
  }

  // Router guards
  if (isLoggedIn) {
    /* const userData = getUserData()
    // Admin
    if (userData.role === 'ROLE_ADMIN' && ([].includes(to.name))) {
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    } */
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
